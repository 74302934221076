import { Config } from './types';

export const baseApiUri = process.env.REACT_APP_API_URL || 'https://inwf4fbyvm.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Navan',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/e9112aa3-ef10-4448-ec38-4bd3cf55c300/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#570099',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
